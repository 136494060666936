@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

:root {
  --grey: #aaaaaa;
  --pink: #ff79c7;
  --purple: #8d7dff;
  --blue: #62cdff;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 300;
  text-align: left;
  margin: 0;
  height: 100%;
  width: 100%;
}

a {
    text-decoration: none;
    color: inherit;
}
