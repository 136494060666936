#page-container {
  position: relative;
  min-height: 100vh;
  overflow-y: hidden;
}

::-webkit-scrollbar {
  width: 0px;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

#content-wrap {
  padding-bottom: 2.5rem; 
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;   
}

.footerRight{
  width: fit-content;
  float: right;
}

.blaze input {
  display: block;
  padding: 10px;
  border-radius: 10px;
  font-size: 1rem;
  background-color: transparent;
  margin-bottom: 20px;
  border: solid 1px #fff;
  width: calc(100% - 20px);
}

.blaze input:active, .blaze input:focus {
  outline: none !important;
  box-shadow: none;
}

.blaze input[type="submit"] {
  color: #fff;
  background-color: var(--pink);
  border: 0;
  cursor: default;
  text-align: center;
  min-width: auto;
  width: auto;
  font-weight: 300;
  line-height: 1.5;
  border-radius: 40px;
  padding-left: 20px !important;
  padding-right: 20px !important;
  margin-top: 10px;
  margin-bottom: 0 !important;
  line-height: 1 !important;
  font-size: 1rem !important;
  cursor: pointer;
  -webkit-appearance:none;
}

input:focus,
input:active {
  outline: none !important;
  box-shadow: none;
}

button{
  cursor: pointer;
}

button:focus,
button:active {
  outline: none !important;
  box-shadow: none;
}

.blaze .switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

/* Hide default HTML checkbox */
.blaze .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.blaze .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ff79c7;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.blaze .slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: #ffffff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.blaze input:checked + .slider {
  background-color: #62cdff;
}

.blaze input:focus + .slider {
  box-shadow: 0 0 1px #eeeeee;
}

.blaze input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.blaze .slider.round {
  border-radius: 17px;
}

.blaze .slider.round:before {
  border-radius: 50%;
}

.blaze .popup {
  position: absolute;
  width: 100px;
  left: -60px;
  border: solid 1px;
  border-radius: 15px;
  padding: 10px;
  line-height: 1.1rem;
  font-size: 1rem;
}

.blaze .rc-menu{
  min-width: fit-content;
  left: 55px !important;
}